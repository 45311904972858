import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, interval, lastValueFrom, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay, switchMap, take, throttleTime } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AuthService, BrokerUserInfo } from './auth.service';
import * as ld from 'launchdarkly-js-client-sdk';
import { NO_ERROR_LOGGING } from './auth-interceptor.service';

/*
WARNING - THIS USES THE tour_customer.feature_flag table, not the regular one
 */

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagService {
  private overrides$$ = new BehaviorSubject<Record<string, boolean>>({});
  private flags$$ = new BehaviorSubject<Record<string, boolean>>(null);
  private flagPoll$$ = new Subject<null>();
  private ldFlags$$ = new BehaviorSubject<Record<string, boolean>>(null);
  private apiUrl$$ = new BehaviorSubject<string>(environment.api);
  private ldClient: ld.LDClient;

  public flags$ = combineLatest([this.flags$$, this.overrides$$, this.ldFlags$$]).pipe(
    filter(([flags]) => !!flags),
    map(([flags, overrides, ldFlags]) => Object.assign({}, flags, ldFlags, overrides)),
    shareReplay(1),
  );

  constructor(private httpClient: HttpClient, private authService: AuthService) {
    this.setupWindowFunctions();
    this.loadFlags();
    this.pollFlags();
    this.setupLaunchDarkly();
  }

  public isFlagActive$(flag: string): Observable<boolean> {
    return this.flags$.pipe(
      map((flags) => {
        return processFlag(flags, flag);
      }),
    );
  }

  private pollFlags() {
    this.flagPoll$$.pipe(throttleTime(5 * 60 * 1000)).subscribe(() => {
      this.loadFlags();
    });
    interval(5 * 60 * 1000).subscribe(() => {
      this.flagPoll$$.next(null);
    });
  }

  private async loadFlags() {
    const flags = await lastValueFrom(
      this.httpClient.get<{ name: string; active: boolean }[]>(
        `${this.apiUrl$$.value}/v1/external/broker_portal/feature_flags`,
      ),
    );
    if (flags) {
      const flagMap: Record<string, boolean> = (flags || []).reduce((acc, flag) => {
        acc[flag.name] = flag.active;
        return acc;
      }, {});
      this.flags$$.next(flagMap);
    } else {
      this.flags$$.next({});
    }
  }

  private setupWindowFunctions() {
    (window as any).listFeatureFlags = () => ({
      production: environment.production,
      flags: this.flags$$.value,
    });

    if (!environment.production) {
      (window as any).setFeatureFlagOverrides = (overrides: Record<string, boolean>) => {
        this.overrides$$.next(overrides || {});
      };
    }
  }

  private setupLaunchDarkly() {
    this.authService.userInfo$
      .pipe(
        filter((user) => !!user),
        distinctUntilChanged((a, b) => a.userId === b.userId),
        switchMap(async (user: BrokerUserInfo) => {
          if (!user) {
            await this.ldClient.close();
            this.ldFlags$$.next({});
            this.ldClient = null;
          }
          return this.initializeLaunchDarkly(user);
        }),
      )
      .subscribe(() => {});
  }

  private async initializeLaunchDarkly(user: BrokerUserInfo): Promise<void> {
    if (this.ldClient) {
      await this.ldClient.close();
      this.ldFlags$$.next({});
      this.ldClient = null;
    }
    const userContext: ld.LDContext = {
      kind: 'user',
      key: `user-key-${user.userId}`,
      name: user.userName,
    };
    const shipperContext: ld.LDContext = {
      kind: 'shipper',
      key: `shipper-key-${user.BrokerAccountId}`,
      name: user.BrokerAccountName,
    };
    const multiContext: ld.LDMultiKindContext = {
      kind: 'multi',
      user: userContext,
      shipper: shipperContext,
    };
    const result = await lastValueFrom(
      this.httpClient.get<{ hash: string }>(`${environment.api}/v1/external/broker_portal/ld_hash`, {
        context: new HttpContext().set(NO_ERROR_LOGGING, true),
      }),
    );

    this.ldClient = ld.initialize(environment.launchDarklyClientSideId, multiContext, {
      hash: result.hash,
      streaming: true,
    });
    await this.ldClient.waitUntilReady();
    this.setLDFlags(this.ldClient.allFlags());
    this.ldClient.on('error', (err) => {
      console.error('LaunchDarkly error', err);
    });
    this.ldClient.on('change', (flags) => {
      this.setLDFlags(flags);
    });
  }

  private setLDFlags(flags: Record<string, any>) {
    const flagsRecord: Record<string, boolean> = Object.keys(flags).reduce((acc, key) => {
      const value = flags[key];
      if (typeof value === 'boolean') {
        acc[key] = value;
      }
      return acc;
    }, {});
    this.ldFlags$$.next(flagsRecord);
  }
}

export function processFlag(flags: Record<string, boolean>, flag: string): boolean {
  let targetState = true;
  if (flag.startsWith('!')) {
    flag = flag.substr(1);
    targetState = false;
  }
  if (Reflect.has(flags, flag)) {
    return flags[flag] === targetState;
  }
  return false;
}
