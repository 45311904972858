<div class="p-4">
  <h2 class="text-2xl font-semibold">New Customer Account</h2>
  <form [formGroup]="form" class="grid grid-cols-2 gap-4">
    <div class="col-span-2">
      <mat-form-field class="w-full">
        <mat-label>Customer Name</mat-label>
        <input matInput autocomplete="off" formControlName="name" />
      </mat-form-field>
    </div>
    <div class="col-span-2">
      <mat-form-field class="w-full">
        <mat-label>Brokerage</mat-label>
        <mat-select formControlName="brokerage">
          <mat-option *ngFor="let brokerage of brokerages; trackBy: trackByKey" [value]="brokerage.internalId">{{
            brokerage.name
          }}</mat-option>
        </mat-select>
        <button mat-icon-button [matTooltip]="brokerageInfo">
          <mat-icon>info</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <fieldset class="col-span-2 border p-2">
      <legend class="font-semibold">Billing Contact Info</legend>
      <div class="grid grid-cols-3 gap-4">
        <mat-form-field class="flex-1">
          <mat-label>Name</mat-label>
          <input matInput autocomplete="off" formControlName="billingContactName" />
        </mat-form-field>
        <mat-form-field class="flex-1">
          <mat-label>Phone</mat-label>
          <ngx-mat-intl-tel-input
            [preferredCountries]="['us']"
            [enablePlaceholder]="true"
            [enableSearch]="true"
            format="national"
            name="phoneNumber"
            formControlName="billingContactPhone"
          ></ngx-mat-intl-tel-input>
        </mat-form-field>
        <mat-form-field class="flex-1">
          <mat-label>Email</mat-label>
          <input matInput autocomplete="off" formControlName="billingContactEmail" />
        </mat-form-field>
        <mat-form-field class="col-span-3">
          <mat-label>Billing Instructions</mat-label>
          <textarea matInput autocomplete="off" formControlName="billingContactBillingInstructions"></textarea>
        </mat-form-field>
      </div>
    </fieldset>
    <!-- Customer Feature Set -->
    <div class="col-span-2">
      <div class="font-semibold w-full">Customer Feature Set</div>
      <mat-radio-group formControlName="featureSet">
        <mat-radio-button value="Drayage">Drayage</mat-radio-button>
        <mat-radio-button value="CPG">CPG</mat-radio-button>
      </mat-radio-group>
    </div>
    <!-- Customer Features -->
    <div class="col-span-2">
      <div class="font-semibold w-full">Customer Features</div>
      <mat-form-field class="w-full">
        <mat-label>Customer Features</mat-label>
        <mat-select formControlName="features" multiple>
          <mat-option *ngFor="let feature of features; trackBy: trackByKey" [value]="feature.name">
            {{ feature.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="flex justify-end col-span-2">
      <button mat-flat-button (click)="createCustomer()" color="primary" [disabled]="form.invalid">Create</button>
      <button mat-flat-button color="warn" (click)="ref.close()">Cancel</button>
    </div>
  </form>
</div>
