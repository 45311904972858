import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SimpleFinn } from '../utilities/simpleFinn';
import { environment } from '../../../environments/environment';
import { lastValueFrom, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface Brokerage {
  internalId: string;
  name: string;
}

@Injectable({
  providedIn: 'root',
})
export class BrokerageService {
  private brokerages: SimpleFinn<Brokerage[]>;

  public get brokerages$(): Observable<Brokerage[]> {
    return this.brokerages.get$();
  }

  constructor(private http: HttpClient) {
    this.brokerages = new SimpleFinn<Brokerage[]>([], this.loadBrokerages);
  }

  private loadBrokerages = (): Promise<Brokerage[]> => {
    return lastValueFrom(
      this.http
        .get<{ internalId: string; name: string }[]>(
          `${environment.api}/v1/external/broker_portal/customers/netsuite_subsidiaries`,
        )
        .pipe(map((v) => v.map((brokerage) => ({ internalId: brokerage.internalId, name: brokerage.name })))),
    );
  };
}
