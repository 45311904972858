import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CustomersService, Customer } from '../../services/customers.service'; // Correct import path
import { FormBuilder, FormGroup } from '@angular/forms';
import { BrokerageService, Brokerage } from '../../services/brokerage.service'; // Import BrokerageService and Brokerage
import { FeaturesService } from '../../services/features.service';
import { SnackBarService } from '../../services/snackbar.service';

@Component({
  selector: 'tb-customer-details',
  templateUrl: './customer-details.component.html',
  styles: [],
})
export class CustomerDetailsComponent implements OnInit {
  public customer: Customer;
  public editMode = false;
  public customerForm: FormGroup;
  public features = [];
  public brokerages: Brokerage[] = [];
  public formChanged = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { customer: Customer; editMode: boolean },
    private dialogRef: MatDialogRef<CustomerDetailsComponent>,
    public customerService: CustomersService,
    private brokerageService: BrokerageService,
    private featuresService: FeaturesService,
    private snackbar: SnackBarService,
    private fb: FormBuilder,
  ) {
    this.customer = data.customer;
    this.editMode = data.editMode;
  }

  public ngOnInit(): void {
    this.initForm();

    this.featuresService.features$.subscribe((features) => {
      this.features = features;
      if (this.editMode) {
        this.customerForm.patchValue({ features: this.customer.features });
      }
    });

    this.brokerageService.brokerages$.subscribe((brokerages) => {
      this.brokerages = brokerages;
      if (this.editMode) {
        const selectedBrokerage = this.brokerages.find((b) => b.name === this.customer.brokerage);
        this.customerForm.patchValue({ brokerage: selectedBrokerage?.internalId });
      }
    });

    this.customerForm.valueChanges.subscribe(() => {
      this.formChanged = true;
    });
  }

  private initForm() {
    this.customerForm = this.fb.group({
      name: [this.customer.name],
      type: [this.customer.type],
      brokerage: [this.customer.brokerage],
      billingContactName: [this.customer.billingContactName],
      billingContactPhone: [this.customer.billingContactPhone],
      billingContactEmail: [this.customer.billingContactEmail],
      billingContactBillingInstructions: [this.customer.billingContactBillingInstructions],
      featureSet: [this.customerService.getFeatureset(this.customer)],
      features: [this.customer.features],
    });
  }

  private isCPGSelected(): boolean {
    return this.customerForm.get('featureSet')?.value === 'CPG';
  }

  public async saveChanges() {
    if (this.customerForm.valid && this.formChanged) {
      const updatedCustomer = { ...this.customer, ...this.customerForm.value };
      if (this.isCPGSelected()) {
        updatedCustomer.featureFlags = updatedCustomer.featureFlags || [];
        if (!updatedCustomer.featureFlags.includes('cpg')) {
          updatedCustomer.featureFlags.push('cpg');
        }
      } else {
        updatedCustomer.featureFlags = (updatedCustomer.featureFlags || []).filter(
          (flag) => flag.toLowerCase() !== 'cpg',
        );
      }
      try {
        await this.customerService.updateCustomer(updatedCustomer);
        this.snackbar.showMessage('Customer Updated');
        this.dialogRef.close(updatedCustomer);
      } catch (error) {
        console.error('Error updating customer:', error);
        this.snackbar.showMessage('Error updating customer');
      }
    }
  }

  public closeDialog() {
    this.dialogRef.close();
  }

  public cancelEdit() {
    this.editMode = false;
    this.initForm();
  }

  public toggleEditMode() {
    this.editMode = !this.editMode;
    if (this.editMode) {
      const selectedBrokerage = this.brokerages.find((b) => b.name === this.customer.brokerage);
      this.customerForm.patchValue({ brokerage: selectedBrokerage?.internalId });
    }
  }

  public trackByKey(index: number, item: any): any {
    return item.id;
  }
}
