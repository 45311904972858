import { ChangeDetectorRef, Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { distinctUntilChanged, map, tap } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { FeatureFlagService } from '../services/feature-flags.service';

@Directive({
  selector: '[tbFeaturePermission]',
})
export class FeaturePermissionDirective {
  private permissionName: string;
  private elseTemplate$$ = new BehaviorSubject<TemplateRef<any>>(null);
  private hasView = false;
  private hasElseView = false;
  private subscription: Subscription;

  @Input()
  public set tbFeaturePermissionElse(elseTemplate: TemplateRef<any>) {
    this.elseTemplate$$.next(elseTemplate);
  }

  @Input()
  public set tbFeaturePermission(flag: string) {
    this.permissionName = flag;
    this.updateView();
  }

  constructor(
    private authService: AuthService,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private featureFlagService: FeatureFlagService,
    private cd: ChangeDetectorRef,
  ) {}

  private updateView() {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = null;
    }
    this.subscription = combineLatest([
      this.featureFlagService.isFlagActive$('cpg').pipe(distinctUntilChanged()),
      this.authService.userInfo$.pipe(
        map((v) => v?.permissions?.length === 0 || v?.permissions.includes(this.permissionName)),
        distinctUntilChanged(),
      ),
      this.elseTemplate$$,
    ]).subscribe(([flagActive, permissionActive, elseTemplate]) => {
      if (!flagActive) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.hasView = true;
        this.cd.markForCheck();
        return;
      }

      if (flagActive && permissionActive) {
        if (!this.hasView) {
          if (this.hasElseView) {
            this.viewContainer.clear();
            this.hasElseView = false;
          }
          this.viewContainer.createEmbeddedView(this.templateRef);
          this.hasView = true;
          this.cd.markForCheck();
        }
      } else if (!!elseTemplate) {
        this.viewContainer.createEmbeddedView(elseTemplate);
        this.hasElseView = true;
        this.cd.markForCheck();
      } else {
        if (this.hasView) {
          this.viewContainer.clear();
          this.hasView = false;
          this.cd.markForCheck();
        }
      }
    });
  }
}
