import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { CustomersService } from '../../services/customers.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NetworkableDestroyableComponent } from '../networkable-destroyable.component';
import { SnackBarService } from '../../services/snackbar.service';
import { MatDialogRef } from '@angular/material/dialog';
import { BrokerageService } from '../../services/brokerage.service';
import { FeaturesService } from '../../services/features.service';
import { Brokerage } from '../../services/brokerage.service';
import { Feature } from '../../services/features.service';

@Component({
  selector: 'tb-create-customer',
  templateUrl: './create-customer.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateCustomerComponent extends NetworkableDestroyableComponent implements OnInit {
  public form: UntypedFormGroup;
  public brokerages: Brokerage[] = [];
  public features = [];
  public brokerageInfo: string = `
  This should be the brokerage which this shipper has signed an agreement with. 
  If you aren’t sure, contact the legal department. 
  (1) LoHi Logistics, LLC — Energy Halliburton 
  (2) Red Rocks Brokerage, LLC — Energy, non-Halliburton 
  (3) Candlestick Freight, LLC — Export/Import 
  (4) Bainbridge Brokerage, LLC — CPG, Retail, Other Short Haul`;

  constructor(
    private customerService: CustomersService,
    private fb: UntypedFormBuilder,
    public ref: MatDialogRef<CreateCustomerComponent>,
    private snackbar: SnackBarService,
    private brokerageService: BrokerageService,
    private featuresService: FeaturesService,
  ) {
    super();
    this.form = this.fb.group({
      name: [null, [Validators.required]],
      type: ['shipper', [Validators.required]],
      brokerage: ['', [Validators.required]],
      billingContactName: [null, []],
      billingContactPhone: [null, []],
      billingContactEmail: [null, []],
      billingContactBillingInstructions: [null, []],
      featureSet: [null, [Validators.required]],
      features: [['my_facilities', 'shipments'], []],
    });
  }

  public ngOnInit(): void {
    this.brokerageService.brokerages$.subscribe((v) => {
      this.brokerages = v;
    });
    this.featuresService.features$.subscribe((v) => {
      this.features = v;
    });
  }
  private isCPGSelected(): boolean {
    return this.form.get('featureSet')?.value === 'CPG';
  }

  public async createCustomer() {
    if (this.form.invalid && this.networkActive$$.value) {
      return;
    }
    try {
      this.networkActive$$.next(true);
      const val = this.form.value;
      if (this.isCPGSelected()) {
        val.featureFlags = val.featureFlags || [];
        if (!val.featureFlags.includes('cpg')) {
          val.featureFlags.push('cpg');
        }
      }
      const succ = await this.customerService.createCustomer(val);
      if (succ) {
        this.snackbar.showMessage('Customer Created');
        this.ref?.close({
          id: succ,
        });
      }
    } finally {
      this.networkActive$$.next(false);
    }
  }
  public trackByKey(index: number, item: any): any {
    return item.id;
  }
}
