import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SimpleFinn } from '../utilities/simpleFinn';
import { environment } from '../../../environments/environment';
import { lastValueFrom, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface Feature {
  name: string;
}

@Injectable({
  providedIn: 'root',
})
export class FeaturesService {
  private features: SimpleFinn<Feature[]>;

  public get features$(): Observable<Feature[]> {
    return this.features.get$();
  }

  constructor(private http: HttpClient) {
    this.features = new SimpleFinn<Feature[]>([], this.loadFeatures);
  }

  private loadFeatures = (): Promise<Feature[]> => {
    return lastValueFrom(
      this.http.get<string[]>(`${environment.api}/v1/external/broker_portal/customers/features`).pipe(
        map((featureNames) => featureNames.map((name) => ({ name }))), // Transform string[] to Feature[]
      ),
    );
  };
}
