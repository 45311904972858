<div class="flex flex-col h-full bg-white dark:bg-gray-900 overflow-y-auto">
  <div class="flex flex-col gap-y-2 flex-1 text-center">
    <div class="p-2 flex flex-row justify-between items-center">
      <!--<a [routerLink]="['/']" class="block">
        <img src="assets/logos/vorto-white.svg" class="hidden dark:block w-[150px]" alt="logo" />
        <img src="assets/logos/vorto.svg" class="dark:hidden w-[150px]" alt="logo" />
      </a>-->
      <mat-icon>close</mat-icon>
    </div>
    <a
      [routerLink]="['/internal']"
      class="font-semibold hover:text-blue-500 text-lg p-2 mx-2 rounded color-transition"
      *tbInternal="null"
    >
      Internal 🤫🤐
    </a>
    <ng-container *tbFeatureFlag="'cpg'; else notCPG">
      <a
        *tbFeaturePermission="'inventories'"
        [routerLink]="['/vmi/inventories']"
        class="font-semibold hover:text-blue-500 text-lg p-2 mx-2 rounded color-transition"
      >
        Inventories
      </a>
      <a
        *tbFeaturePermission="'autofulfillment'"
        [routerLink]="['/autofulfillment']"
        class="font-semibold hover:text-blue-500 text-lg p-2 mx-2 rounded color-transition"
      >
        AutoFulfillment
      </a>
      <ng-container *tbFeatureFlag="'viewPerformanceDashboard'">
        <a
          *tbFeaturePermission="'performance_dashboard'"
          [routerLink]="['/performance_dashboard']"
          class="font-semibold hover:text-blue-500 text-lg p-2 mx-2 rounded color-transition"
        >
          Performance Dashboard
        </a>
      </ng-container>
      <ng-container *tbFeatureFlag="'viewFreightPools'">
        <a
          *tbFeaturePermission="'freight_pool'"
          [routerLink]="['/freight_pool']"
          class="font-semibold hover:text-blue-500 text-lg p-2 mx-2 rounded color-transition"
        >
          Freight Pool
        </a>
      </ng-container>
      <a
        *tbFeaturePermission="'my_facilities'"
        [routerLink]="['/vst']"
        class="font-semibold hover:text-blue-500 text-lg p-2 mx-2 rounded color-transition"
      >
        My Facilities
      </a>
      <a
        *tbFeaturePermission="'recommendations'"
        [routerLink]="['/recommendations']"
        class="font-semibold hover:text-blue-500 text-lg p-2 mx-2 rounded color-transition"
      >
        Recommendations
      </a>
      <a
        [routerLink]="['/facilities/dwell_sla']"
        class="font-semibold hover:text-blue-500 text-lg p-2 mx-2 rounded color-transition"
      >
        Dwell Time SLAs
      </a>
      <a
        *tbFeaturePermission="'trailer_dashboard'"
        [routerLink]="['/vst/asset_dashboard']"
        class="font-semibold hover:text-blue-500 text-lg p-2 mx-2 rounded color-transition"
      >
        Trailer Dashboard
      </a>
      <a
        *tbFeatureFlag="'rodni'"
        [routerLink]="['/document_importer']"
        class="font-semibold hover:text-blue-500 text-lg p-2 mx-2 rounded color-transition"
      >
        RODNi
      </a>
      <a
        [routerLink]="['/planner_dashboard']"
        class="font-semibold hover:text-blue-500 text-lg p-2 mx-2 rounded color-transition"
      >
        Planning Dashboard
      </a>
      <a
        *tbInternal="null"
        [routerLink]="['/auto_booking_dashboard']"
        class="font-semibold hover:text-blue-500 text-lg p-2 mx-2 rounded color-transition"
      >
        Auto Booking Dashboard
      </a>
      <a
        *tbFeatureFlag="'streamlinedLoadCreation'"
        [routerLink]="['/streamlined_load_creation']"
        class="font-semibold hover:text-blue-500 text-lg p-2 mx-2 rounded color-transition"
      >
        Automatic Load Ordering
      </a>
      <ng-container *tbFeatureFlag="'viewRegionalSimulation'">
        <a
          [routerLink]="['/autoorder']"
          class="font-semibold hover:text-blue-500 text-lg p-2 mx-2 rounded color-transition"
        >
          Regional Simulation
        </a>
      </ng-container>
    </ng-container>

    <ng-template #notCPG>
      <ng-container *tbFeatureFlag="'viewSnoTool'; else noSno"></ng-container>
      <ng-template #noSno>
        <a
          [routerLink]="['/loads']"
          class="font-semibold hover:text-blue-500 text-lg p-2 mx-2 rounded color-transition"
        >
          Quotes
        </a>
      </ng-template>
    </ng-template>
    <a
      *tbFeaturePermission="'shipments'"
      [routerLink]="['/loads/awarded']"
      class="font-semibold hover:text-blue-500 text-lg p-2 mx-2 rounded color-transition"
    >
      Shipments
    </a>
    <a
      *tbInternalOrExternalDispatcher="null"
      [routerLink]="['/internal/edi']"
      class="font-semibold hover:text-blue-500 text-lg p-2 mx-2 rounded color-transition"
    >
      EDI Dashboard
    </a>
    <ng-container *tbFeatureFlag="'viewSnoTool'">
      <a
        *tbFeaturePermission="'sna'"
        [routerLink]="['/shipper_network_analysis']"
        class="font-semibold hover:text-blue-500 text-lg p-2 mx-2 rounded color-transition"
      >
        Shipper Network Analysis
      </a>
    </ng-container>
    <a
      *tbFeatureFlag="'viewShipmentPlanner'"
      [routerLink]="['/shipment_planner']"
      class="font-semibold hover:text-blue-500 text-lg p-2 mx-2 rounded color-transition"
    >
      Shipment Planner
    </a>
    <a
      *tbFeatureFlag="'viewShipmentPlannerV2'"
      [routerLink]="['/shipment_planner/v2']"
      class="font-semibold hover:text-blue-500 text-lg p-2 mx-2 rounded color-transition"
    >
      Shipment Planner v2
    </a>
    <a
      *tbFeatureFlag="'viewShipmentPlannerV3'"
      [routerLink]="['/shipment_planner/v3']"
      class="font-semibold hover:text-blue-500 text-lg p-2 mx-2 rounded color-transition"
    >
      Shipment Planner v3
    </a>
    <a
      *tbFeatureFlag="'viewShipmentPlannerV4'"
      [routerLink]="['/shipment_planner/v4']"
      class="font-semibold hover:text-blue-500 text-lg p-2 mx-2 rounded color-transition"
    >
      Shipment Planner v4
    </a>
    <ng-container *tbAccountType="'agent'">
      <a
        [routerLink]="['/metrics']"
        class="font-semibold hover:text-blue-500 text-lg p-2 mx-2 rounded color-transition"
      >
        Earnings
      </a>
      <a
        [routerLink]="['/loads/award/otr/other']"
        class="font-semibold hover:text-blue-500 text-lg p-2 mx-2 rounded color-transition"
      >
        Create Shipment
      </a>
      <a
        [routerLink]="['/loads/upload']"
        class="font-semibold hover:text-blue-500 text-lg p-2 mx-2 rounded color-transition"
      >
        Upload Shipments
      </a>
      <a
        [routerLink]="['/loads/upload_drayage_export']"
        class="font-semibold hover:text-blue-500 text-lg p-2 mx-2 rounded color-transition"
      >
        Upload Drayage Export Shipments
      </a>
    </ng-container>
    <ng-container *tbFeatureFlag="'enterpriseCustomer'">
      <div class="text-gray-300 w-full text-center">the hidden AD menu</div>
      <a
        [routerLink]="['./', 'carrier_management']"
        class="font-semibold hover:text-blue-500 text-lg p-2 mx-2 rounded color-transition"
      >
        Carrier Management
      </a>
      <a
        [routerLink]="['./', 'autodispatch_pools']"
        class="font-semibold hover:text-blue-500 text-lg p-2 mx-2 rounded color-transition"
      >
        My Pools
      </a>
    </ng-container>
    <a
      *tbFeaturePermission="'shipments'"
      (click)="openLoadSearch()"
      class="font-semibold hover:text-blue-500 text-lg p-2 mx-2 rounded color-transition"
    >
      Search for Loads&nbsp;<span class="bg-gray-600/20 px-1 p-px">Ctrl+K</span>
    </a>
    <a
      [routerLink]="['/rebate']"
      class="font-semibold hover:text-blue-500 text-lg p-2 mx-2 rounded color-transition"
      *ngIf="(userInfo$ | async)?.enableRebateProgram"
    >
      Rebate
    </a>
    <ng-container *tbFeatureFlag="'cpg'">
      <a
        *tbFeaturePermission="'product_management'"
        [routerLink]="['/vmi/products']"
        class="font-semibold hover:text-blue-500 text-lg p-2 mx-2 rounded color-transition"
      >
        Products
      </a>
    </ng-container>
    <ng-container *tbFeatureFlag="'cpg'; else notCPGFacilities"> </ng-container>
    <ng-template #notCPGFacilities>
      <a
        [routerLink]="['/facilities']"
        class="font-semibold hover:text-blue-500 text-lg p-2 mx-2 rounded color-transition"
      >
        Facilities
      </a>
    </ng-template>
    <a
      *tbFeaturePermission="'user_management'"
      [routerLink]="['/users']"
      class="font-semibold hover:text-blue-500 text-lg p-2 mx-2 rounded color-transition"
    >
      Users
    </a>
    <div class="flex flex-row justify-center divide-x divide-gray-600 p-2 pb-3 mt-auto">
      <div class="pr-2 max-w-1/3" *ngIf="userInfo$ | async as user">
        <div class="font-semibold">
          {{ user.userName }}
        </div>
        <div>
          {{ user.BrokerAccountName }}
        </div>
      </div>
      <div class="px-2">
        <button mat-button (click)="downloadShipmentExport()">CSV Export</button>
      </div>
      <div class="pl-2">
        <button mat-button (click)="logout()">Logout</button>
      </div>
    </div>
  </div>
</div>
