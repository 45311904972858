export const shareReplayComponentConfig = {
  bufferSize: 1,
  refCount: true,
};

export const unitsForFlatFile = [
  {
    value: 1,
    label: 'kilograms',
  },
  {
    value: 2,
    label: 'pounds',
  },
  {
    value: 3,
    label: 'tons',
  },
  {
    value: 4,
    label: 'boxes',
  },
  {
    value: 5,
    label: 'gallons',
  },
  {
    value: 6,
    label: 'liters',
  },
  {
    value: 7,
    label: 'barrels',
  },
  {
    value: 8,
    label: 'each',
  },
  {
    value: 9,
    label: 'MSCF',
  },
  {
    value: 10,
    label: 'pallets',
  },
  {
    value: 11,
    label: 'pieces',
  },
  {
    value: 12,
    label: 'bales',
  },
  {
    value: 13,
    label: 'drums',
  },
  {
    value: 14,
    label: 'bags',
  },
];

export const hours = [
  '12am',
  '1am',
  '2am',
  '3am',
  '4am',
  '5am',
  '6am',
  '7am',
  '8am',
  '9am',
  '10am',
  '11am',
  '12pm',
  '1pm',
  '2pm',
  '3pm',
  '4pm',
  '5pm',
  '6pm',
  '7pm',
  '8pm',
  '9pm',
  '10pm',
  '11pm',
];

export const FACILITY_ID = 'FACILITY_ID';
export const TRAILER_ID = 'TRAILER_ID';
export const LOAD_ID = 'LOAD_ID';

export const NO_CONTENT_TYPE_HEADER = 'X-No-Content-Type';

export const IMPORTER = 'IMPORTER';
export const IMPORT_ID = 'IMPORT_ID';

export const CREATOR = 'CREATOR';
