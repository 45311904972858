import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, shareReplay } from 'rxjs/operators';
import {
  BehaviorSubject,
  catchError,
  debounceTime,
  firstValueFrom,
  lastValueFrom,
  Observable,
  of,
  Subject,
  switchMap,
} from 'rxjs';
import { GlobalSearchResult, LoadDetails } from '../../../shared/types';
import { RouterFinn } from 'src/app/shared/utilities/routerFinn';
import { LOAD_ID } from '../../../shared/constants';
import { RouterStateService } from '../../../shared/services/router-state.service';
import { environment } from '../../../../environments/environment';
import { LoadChargeDetails } from '../../loads/types';

@Injectable({
  providedIn: 'root',
})
export class VstLoadsService {
  private readonly loadId$ = this.routerState.listenForParamChange$(LOAD_ID).pipe(shareReplay(1));

  private loadDetails: RouterFinn<LoadDetails>;
  public get loadDetails$() {
    return this.loadDetails.get$();
  }

  private charges: RouterFinn<LoadChargeDetails>;
  public get charges$() {
    return this.charges.get$();
  }

  private searchText$$ = new Subject<string>();
  private loading$$ = new BehaviorSubject<boolean>(false);
  private searchResults$$ = new BehaviorSubject<GlobalSearchResult[]>([]);

  public get searchResults$(): Observable<GlobalSearchResult[]> {
    return this.searchResults$$;
  }

  public get loading$(): Observable<boolean> {
    return this.loading$$;
  }

  constructor(private http: HttpClient, private routerState: RouterStateService) {
    this.loadDetails = new RouterFinn<LoadDetails>(null, this.loadId$, this.getLoadDetails);
    this.charges = new RouterFinn<LoadChargeDetails>(null, this.loadId$, this.getLoadCharges, false);

    this.listenToSearchTextChange();
  }

  private getLoadDetails = async (loadId: string) => {
    return lastValueFrom(
      this.http.get<LoadDetails>(`${environment.api}/v1/external/broker_portal/vst/loads/${loadId}`),
    );
  };

  public refreshLoad() {
    this.loadDetails.refresh();
  }

  public async toggleLoadAlerts(loadId: string, enabled = true) {
    try {
      await lastValueFrom(
        this.http.post<void>(`${environment.api}/v1/external/broker_portal/vst/loads/${loadId}/notifications`, {
          enabled,
        }),
      );
      this.loadDetails.refresh();
      return true;
    } catch (e) {
      return false;
    }
  }

  public async setExternalTrailerNumber(loadId: string, externalTrailerNumber: string) {
    try {
      await lastValueFrom(
        this.http.post<void>(
          `${environment.api}/v1/external/broker_portal/vst/loads/${loadId}/external_trailer_number`,
          {
            externalTrailerNumber,
          },
        ),
      );
      this.loadDetails.refresh();
      return true;
    } catch (e) {
      return false;
    }
  }

  public async setReadyAtTime(loadId: string, readyTime: string, timezone: string) {
    try {
      await lastValueFrom(
        this.http.post<void>(`${environment.api}/v1/external/broker_portal/vst/loads/${loadId}/update_ready_at`, {
          readyTime,
          timezone,
        }),
      );
      this.loadDetails.refresh();
      return true;
    } catch (e) {
      return false;
    }
  }

  public search(text: string) {
    this.searchText$$.next(text);
  }

  private listenToSearchTextChange() {
    this.searchText$$
      .pipe(debounceTime(50))
      .pipe(
        switchMap((searchText) => {
          this.loading$$.next(true);
          if (searchText) {
            return this.http
              .get<{ results: GlobalSearchResult[] }>(
                `${environment.api}/v1/external/broker_portal/vst/global_search`,
                {
                  params: { q: searchText },
                },
              )
              .pipe(catchError(() => of({ results: new Array<GlobalSearchResult>() })));
          } else {
            return of({ results: new Array<GlobalSearchResult>() });
          }
        }),
      )
      .subscribe((results) => {
        this.loading$$.next(false);
        this.searchResults$$.next(results?.results || []);
      });
  }

  public async getPODPDF(loadId: string) {
    try {
      const blob = await lastValueFrom(
        this.http.get(`${environment.api}/v1/external/broker_portal/vst/loads/${loadId}/proof_of_delivery`, {
          responseType: 'blob',
        }),
      );
      return new Blob([blob], { type: 'application/pdf' });
    } catch (e) {
      return false;
    }
  }

  private getLoadCharges = (loadID: string) => {
    return firstValueFrom(
      this.http.get<LoadChargeDetails>(`${environment.api}/v1/external/broker_portal/loads/lohi_charges`, {
        params: {
          loadID,
        },
      }),
    );
  };

  public async submitRating(id: string, rating: number, description: string) {
    try {
      await lastValueFrom(
        this.http.post<void>(`${environment.api}/v1/external/broker_portal/vst/loads/${id}/rate`, {
          rating,
          description,
        }),
      );
      this.loadDetails.refresh();
      return true;
    } catch (e) {
      return false;
    }
  }

  public sendETAFeedback(
    stopID: number,
    eta: string,
    feedback: string,
    notPopulating: boolean,
    incorrect: boolean,
    willBeLate: boolean,
  ) {
    return lastValueFrom(
      this.http
        .post<void>(`${environment.api}/v1/external/broker_portal/vst/loads/eta_feedback`, {
          stopID,
          eta,
          feedback,
          notPopulating,
          incorrect,
          willBeLate,
        })
        .pipe(
          map(() => true),
          catchError(() => of(false)),
        ),
    );
  }
}
