<div class="p-4">
  <h2 class="text-2xl font-semibold">{{ editMode ? 'Edit Customer' : 'View Detail' }}</h2>
  <form [formGroup]="customerForm" class="grid grid-cols-2 gap-4">
    <div class="col-span-2">
      <label class="font-semibold">Name:</label>
      <span class="ml-2">{{ customer.name }}</span>
    </div>
    <div class="col-span-2">
      <label class="font-semibold">Brokerage:</label>
      <span *ngIf="!editMode" class="ml-2">{{ customer.brokerage }}</span>
      <mat-form-field *ngIf="editMode" class="w-full" appearance="fill">
        <mat-select formControlName="brokerage">
          <mat-option *ngFor="let brokerage of brokerages; trackBy: trackByKey" [value]="brokerage.internalId">
            {{ brokerage.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <fieldset class="col-span-2 border p-2">
      <legend class="font-semibold">Billing Contact Info</legend>
      <div class="grid grid-cols-3 gap-4">
        <div>
          <label class="font-semibold">Name:</label>
          <span *ngIf="!editMode" class="ml-2">{{ customer.billingContactName }}</span>
          <mat-form-field *ngIf="editMode" class="w-full" appearance="fill">
            <input matInput formControlName="billingContactName" />
          </mat-form-field>
        </div>
        <div>
          <label class="font-semibold">Phone:</label>
          <span *ngIf="!editMode" class="ml-2">{{ customer.billingContactPhone }}</span>
          <mat-form-field *ngIf="editMode" class="w-full" appearance="fill">
            <input matInput formControlName="billingContactPhone" />
          </mat-form-field>
        </div>
        <div>
          <label class="font-semibold">Email:</label>
          <span *ngIf="!editMode" class="ml-2">{{ customer.billingContactEmail }}</span>
          <mat-form-field *ngIf="editMode" class="w-full" appearance="fill">
            <input matInput formControlName="billingContactEmail" />
          </mat-form-field>
        </div>
        <div class="col-span-3">
          <label class="font-semibold">Billing Instructions:</label>
          <span *ngIf="!editMode" class="ml-2">{{ customer.billingContactBillingInstructions }}</span>
          <mat-form-field *ngIf="editMode" class="w-full" appearance="fill">
            <textarea matInput formControlName="billingContactBillingInstructions"></textarea>
          </mat-form-field>
        </div>
      </div>
    </fieldset>
    <div class="col-span-2">
      <label class="font-semibold">Customer Feature Set:</label>
      <span *ngIf="!editMode" class="ml-2">{{ customerService.getFeatureset(customer) }}</span>
      <mat-radio-group *ngIf="editMode" formControlName="featureSet">
        <mat-radio-button value="Drayage">Drayage</mat-radio-button>
        <mat-radio-button value="CPG">CPG</mat-radio-button>
      </mat-radio-group>
    </div>
    <div class="col-span-2">
      <label class="font-semibold">Customer Features:</label>
      <div *ngIf="!editMode" class="ml-2">
        <div *ngFor="let feature of customer.features; trackBy: trackByKey">{{ feature }}</div>
      </div>
      <mat-form-field *ngIf="editMode" class="w-full" appearance="fill">
        <mat-select formControlName="features" multiple>
          <mat-option *ngFor="let feature of features; trackBy: trackByKey" [value]="feature.name">
            {{ feature.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="flex justify-end col-span-2">
      <button *ngIf="!editMode" mat-flat-button color="primary" (click)="toggleEditMode()">Edit</button>
      <button *ngIf="editMode" mat-flat-button color="primary" (click)="saveChanges()" [disabled]="!formChanged">
        Save
      </button>
      <button mat-flat-button color="warn" (click)="closeDialog()">
        {{ editMode ? 'Cancel' : 'Close' }}
      </button>
    </div>
  </form>
</div>
