import { Injectable } from '@angular/core';
import { CustomerType } from './auth.service';
import { SimpleFinn } from '../utilities/simpleFinn';
import { lastValueFrom, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { catchError, map } from 'rxjs/operators';

export interface Customer {
  id: string;
  name: string;
  type: CustomerType;
  brokerage: string; // Brokerage id as string
  billingContactName: string | null;
  billingContactPhone: string | null;
  billingContactEmail: string | null;
  billingContactBillingInstructions: string | null;
  notifyOfDetentionMinutes: number;
  detentionCents: number;
  detentionStartsAfterMinutes: number;
  featureFlags: string[];
  features: string[];
  isCPG: boolean;
  userCount: number;
}

@Injectable({
  providedIn: 'root',
})
export class CustomersService {
  private customers: SimpleFinn<Customer[]>;

  public get customers$(): Observable<Customer[]> {
    return this.customers.get$();
  }

  public get customersExcludingAgents$(): Observable<Customer[]> {
    return this.customers.get$().pipe(map((value) => value.filter((value1) => value1.type !== 'agent')));
  }

  constructor(private http: HttpClient) {
    this.customers = new SimpleFinn<Customer[]>([], this.loadCustomers);
  }

  private loadCustomers = (): Promise<Customer[]> => {
    return lastValueFrom(
      this.http.get<{ companies: Customer[] }>(`${environment.api}/v1/external/broker_portal/customers`).pipe(
        map((response) => response.companies || []),
        catchError((error) => {
          console.error('Error loading customers:', error);
          return of([]);
        }),
      ),
    );
  };

  public async createCustomer(value: Customer): Promise<string> {
    try {
      const res = await lastValueFrom(
        this.http.post<{ id: string }>(`${environment.api}/v1/external/broker_portal/customers/create_customer`, value),
      );
      this.customers.get$();
      return res.id;
    } catch (error) {
      console.error('Error creating customer:', error);
      return null;
    }
  }

  public async updateCustomer(value: Customer): Promise<void> {
    try {
      await lastValueFrom(
        this.http.put<void>(`${environment.api}/v1/external/broker_portal/customers/${value.id}`, value),
      );
      this.customers.get$();
    } catch (error) {
      console.error('Error updating customer:', error);
      throw error;
    }
  }

  public getFeatureset(customer: Customer): string {
    if (!customer.featureFlags) {
      return 'Drayage';
    }
    return customer.featureFlags.some((flag) => flag.toLowerCase() === 'cpg') ? 'CPG' : 'Drayage';
  }
}
